import { StylesConfig } from "react-select"
import { SelectOption } from "../select/select-option"

export const stylesSelect: StylesConfig<SelectOption> = {
  control: (base) => ({
    ...base,
    borderColor: "#ddd",
    boxSizing: "border-box",
    boxShadow: "none",
    ":hover": { borderColor: "#ddd" },
  }),
  menu: (defaultStyle) => ({
    ...defaultStyle,
    borderRadius: 0,
    marginTop: 1,
  }),
  menuList: (defaultStyle) => ({
    ...defaultStyle,
    overflowY: "auto",
    borderRadius: 0,
  }),
  option: (defaultStyle, state) => ({
    ...defaultStyle,
    ":hover": { backgroundColor: "#f4f4f4" },
    backgroundColor: state.isSelected ? "#f4f4f4" : "white",
    fontWeight: "normal",
    color: "#444444",
    textAlign: "left",
    cursor: "pointer",
  }),
  indicatorSeparator: (defaultStyle) => ({
    ...defaultStyle,
    display: "none",
  }),
  indicatorsContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: 0,
    ">div": {
      padding: 5,
      cursor: "pointer",
    },
  }),
}
