import { initializeSentry } from "@rc/multi-product-util"
import { ENV } from "@rc/shared-react-common"
import { StrictMode } from "react"
import * as ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { router } from "./router/router"

if (ENV.PROD) initializeSentry()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
)
