import { ENV } from "@rc/shared-react-common"
import { User } from "@rc/shared-types"
import { isCustomerUser, isValidUser } from "@rc/shared-user-catalog"
import { isEqual } from "lodash"
import { ReactNode, useCallback, useEffect } from "react"
import churnZero from "../churn-zero/churn-zero"
import googleTag from "../google/google-tag"
import hotjar from "../hotjar/hotjar"
import intercom from "../intercom/intercom"

type Options = "GoogleAnalytics" | "ChurnZero" | "Hotjar" | "Intercom"

interface Props {
  children: ReactNode
  user: User
  initialize: "ALL" | Options[]
  enableAllUsers?: boolean
}
export function ThirdPartyAppsInitializer({
  children,
  user,
  initialize,
  enableAllUsers = false,
}: Props) {
  const isEnabled = useCallback(
    (value: Options) =>
      isEqual(initialize, "ALL") ||
      isEqual(initialize, value) ||
      initialize.includes(value),
    [initialize],
  )

  useEffect(
    function setupThirdParty() {
      if (
        isValidUser(user.username) &&
        (enableAllUsers || isCustomerUser(user.role))
      ) {
        if (isEnabled("GoogleAnalytics"))
          googleTag.initialize(ENV.GOOGLE_TAG_ID)

        if (isEnabled("Hotjar"))
          hotjar.initialize(ENV.HOTJAR_ID, ENV.HOTJAR_VERSION)
      }

      if (isCustomerUser(user.role) || ENV.DEV) {
        if (isEnabled("Intercom")) intercom.initialize(ENV.INTERCOM_APP_ID)

        if (isEnabled("ChurnZero"))
          churnZero.initialize(ENV.CHURNZERO_API, ENV.CHURNZERO_APP_KEY)
      }
    },
    [enableAllUsers, isEnabled, user],
  )

  return children
}
