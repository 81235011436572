import { StylesConfig } from "react-select"
import { SelectOption } from "../select/select-option"

export const stylesMultiSelect: StylesConfig<SelectOption> = {
  container: (base) => ({
    ...base,
    minHeight: 30,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 3,
    borderColor: "#ddd",
    boxSizing: "border-box",
    boxShadow: "none",
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#9B9B9B",
    minHeight: 30,
    ":hover": { borderColor: "#ddd" },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    minHeight: 30,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    borderColor: "#ddd",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#555",
  }),
  option: (base) => ({
    ...base,
    ":hover": { backgroundColor: "#f4f4f4" },
    backgroundColor: "transparent",
    cursor: "pointer",
  }),
  placeholder: (base) => ({ ...base, color: "#aaa" }),
  multiValue: (base) => ({
    ...base,
    height: 21,
    flexDirection: "row-reverse",
    color: "#555555",
    backgroundColor: "transparent",
    border: "1px solid #72D5AB",
    "div:nth-of-type(1)": {
      padding: "4px 3px 3px 3px",
    },
    "div:nth-of-type(2)": {
      cursor: "pointer",
      color: "#72D5AB",
      borderRight: "1px solid #72D5AB",
      ":hover": {
        backgroundColor: "rgb(114, 213, 171, .2)",
      },
    },
  }),
  multiValueLabel: (base) => ({ ...base, alignSelf: "center" }),
}
