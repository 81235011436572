import { CalendarMonth } from "@mui/icons-material"
import { ForwardedRef, forwardRef } from "react"
import "./input-datepicker.scss"

export const InputDatePicker = forwardRef(function CustomInput(
  { value, onClick }: { value?: string; onClick?: () => void },
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      data-testid="input-datepicker"
      className="input-datepicker"
      onClick={onClick}
      ref={ref}>
      {value}
      <div className="calendar-icon">
        <CalendarMonth />
      </div>
    </div>
  )
})
