import { ExitToApp } from "@mui/icons-material"
import { useLogout } from "@rc/shared-auth-provider"
import "./logout.scss"

function Logout() {
  const logout = useLogout()

  return (
    <div data-testid="logout" className="logout" onClick={logout}>
      <ExitToApp />
      Cerrar sesión
    </div>
  )
}

export { Logout }
