import * as Sentry from "@sentry/react"

// Sentry API: https://docs.sentry.io/platforms/javascript/guides/react/
export function initializeSentry() {
  Sentry.init({
    dsn: "https://a23521843e370eb0c51221871f4107c5@o583617.ingest.us.sentry.io/4506853376262144",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
