import { isLocalhost } from "@rc/shared-common-util"
import { Application, Store, User } from "@rc/shared-types"
import { filter, first } from "lodash"
import { CUSTOMER_ROLES, USER_NOT_ALLOWED } from "./constants"

export function filterStoresByModule(
  stores: Store[],
  application: Application,
): Store[] {
  const availableStores = filter(stores, "allowedApps").filter((store) =>
    store.allowedApps.some((a) => a === application),
  )
  return availableStores
}

export function getDefaultStore(stores: Store[]): Store {
  const store = first(filter(stores, ["main", true]))
  return store ?? stores[0]
}

export function isCustomerUser(role: string | null): boolean {
  if (!role) return false
  return CUSTOMER_ROLES.includes(role)
}

export function isAllowedUser(username: string | undefined): boolean {
  if (!username) return true
  return !USER_NOT_ALLOWED.some((user) => username.includes(user))
}

export const isRealUser = (user: User) => {
  return isCustomerUser(user.role) && isValidUser(user.username)
}

export const isValidUser = (username: string) => {
  return !isLocalhost() && isAllowedUser(username)
}
