import { useMultiProductStore } from "@rc/multi-product-state"
import {
  GOOGLE_TAG_CATEGORY,
  GOOGLE_TAG_LABEL,
  LOCAL_MODULES,
} from "@rc/multi-product-util"
import { classNames } from "@rc/shared-common-util"
import { googleTag } from "@rc/shared-third-party-tools"
import { ProductAccess } from "@rc/shared-types"
import { ElementWithTooltip } from "@rc/shared-ui"
import { useNavigate } from "react-router"
import "./multi-product-navbar-item.scss"

interface ItemProps {
  item: ProductAccess
}

export function MultiProductNavbarItem({ item }: Readonly<ItemProps>) {
  const navigate = useNavigate()
  const module = useMultiProductStore.use.module()
  const active = item.internalName === module.internalName

  const itemClass = classNames({
    "mp-navbar-item": true,
    disable: !item.enabled,
    active,
  })

  const handleClick = () => {
    if (!item.enabled || active) return

    googleTag.event(`/${item.internalName}/CLICK`, {
      event_category: GOOGLE_TAG_CATEGORY.CATEGORY_EVENT_UNIFIED_MENU,
      event_label: GOOGLE_TAG_LABEL.LABEL_EVENT_MENU_BAR_LEFT,
    })

    LOCAL_MODULES.includes(item.internalName)
      ? navigate(item.pathname)
      : window.open(item.url, "_self")
  }

  return (
    <ElementWithTooltip
      placement="right"
      element={
        <div data-testid="navbar-item" onClick={handleClick}>
          <div className={itemClass}>
            <img src={item.iconUrl} alt={item.name} />
          </div>
        </div>
      }>
      {item.name}
    </ElementWithTooltip>
  )
}
