class Hotjar {
  hasLoaded = false

  initialize(id: string, version: string) {
    if (this.hasLoaded) return

    const script = document.createElement("script")
    script.text = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${id},hjsv:${version}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');    
    `
    document.head.appendChild(script)

    this.hasLoaded = true
  }
}

export default new Hotjar()
