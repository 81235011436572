export const USER_ROLES = {
  CLIENT: "customer",
  CLIENT_ADMIN: "client_admin",
  DEMO_ACCOUNT: "demo_account",
}

export const CUSTOMER_ROLES = [
  USER_ROLES.CLIENT,
  USER_ROLES.CLIENT_ADMIN,
  USER_ROLES.DEMO_ACCOUNT,
]

export const USER_NOT_ALLOWED = ["qa_marca", "qa_retail", "cypress-bot"]
