import { queryClient } from "@rc/shared-query-provider"
import { getChurnZeroUserTokenQuery } from "@rc/shared-third-party-api"
import { churnZero } from "@rc/shared-third-party-tools"
import { Application, ProductAccess, Store, User } from "@rc/shared-types"
import { CHURN_ZERO_PHOENIX } from "./churn-zero-constants"

export const LOGIN_EVENT: { [key: string]: string } = {
  [Application.PHOENIX]: CHURN_ZERO_PHOENIX.LOGIN,
}

export async function churnZeroSetup(
  user: User,
  store: Store,
  module: ProductAccess,
) {
  const token = await queryClient.fetchQuery(
    getChurnZeroUserTokenQuery({ userId: user.id, storeId: store.id }),
  )
  churnZero.setAuth(() => ({
    userId: user.id,
    storeId: store.id,
    token,
  }))
  churnZero.setContact(String(store.id), String(user.id))
  churnZeroLoginEvent(user, store, module)
}

export function churnZeroLoginEvent(
  user: User,
  store: Store,
  module: ProductAccess,
) {
  const sessionId = `churnzero-${user.id}-${store.id}-${module.internalName}`
  const isLoggedIn = sessionStorage.getItem(sessionId)
  if (isLoggedIn) return

  const event = LOGIN_EVENT[module.internalName]
  if (event) churnZero.trackEvent(event)

  sessionStorage.setItem(sessionId, "true")
}
