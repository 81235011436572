import { Legend } from "@rc/shared-types"
import { forwardRef, useImperativeHandle, useState } from "react"
import "./multi-bar-chart-legend.scss"

interface ChartLegendProps {
  data: Legend[]
  onSelectionChange: (selectedIds: number[]) => void
}

export const ChartLegend = forwardRef(
  ({ data, onSelectionChange }: Readonly<ChartLegendProps>, ref) => {
    const [selectedIds, setSelectedIds] = useState<number[]>([])

    const handleSelectionChange = (id: number) => {
      const isSelected = selectedIds.includes(id)
      let newSelectedIds: number[]

      if (isSelected) {
        newSelectedIds = selectedIds.filter((selectedId) => selectedId !== id)
      } else {
        newSelectedIds = [...selectedIds, id]
      }

      setSelectedIds(newSelectedIds)
      onSelectionChange(newSelectedIds)
    }

    useImperativeHandle(ref, () => ({
      clearSelection() {
        setSelectedIds([])
      },
    }))

    return (
      <div className="chart-legend">
        {data.map((item) => (
          <div
            key={item.id}
            data-testid="chart-legend-item"
            className={`chart-legend-item ${
              selectedIds.includes(item.id) ? "selected" : ""
            }`}
            onClick={() => handleSelectionChange(item.id)}>
            <span
              className="chart-legend-color"
              style={{ backgroundColor: item.color }}
            />{" "}
            {item.name}
          </div>
        ))}
      </div>
    )
  },
)
