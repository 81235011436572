import {
  fetchProductAccess,
  parseProductAccess,
} from "@rc/shared-store-catalog"
import { fetchSessionInformation } from "@rc/shared-user-catalog"
import { queryOptions } from "@tanstack/react-query"

export function userDataQuery(userId: number) {
  return queryOptions({
    queryKey: ["user_data", userId],
    queryFn: async () => {
      const sessionInformation = await fetchSessionInformation(userId)
      const { stores } = sessionInformation
      const storeIds = stores.map((store) => store.id)
      const productAccessResponse = await fetchProductAccess(storeIds)
      const productAccesses = parseProductAccess(productAccessResponse, stores)
      return { sessionInformation, productAccesses }
    },
  })
}
