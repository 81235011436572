export const GOOGLE_TAG_ACTION = {
  EVENT_DESCARGAR_EXCEL: "/PRODUCT/LIST/DOWNLOAD_SHEET",
  EVENT_DOWNLOAD_SELECT_OPTIONS: "/PRODUCT/LIST/DOWNLOAD/SELECT_OPTIONS",
  EVENT_LABEL_CREATION_INTENTION: " LABEL/CREATION/INTENTION",
  EVENT_LABEL_CREATED: " LABEL/CREATED",
  EVENT_LABEL_EDITION_INTENTION: " LABEL/EDITION/INTENTION",
  EVENT_LABEL_EDITED: " LABEL/EDITED",
  ERROR_REPORT_INTENTION: "/PRODUCT/DETAIL/ERROR/REPORT_INTENTION",
  ERROR_REPORT: "/PRODUCT/DETAIL/ERROR/REPORTED",
  EVENT_ADD_MATCHES_INTENTION: " PRODUCT/DETAIL/MATCHES/ADD_INTENTION",
  EVENT_ADD_MATCHES_SEARCH: " PRODUCT/DETAIL/MATCHES/SEARCH",
  EVENT_ADD_MATCHES_ADDED: " PRODUCT/DETAIL/MATCHES/ADDED",
  EVENT_ADD_MATCHES_ERROR: " PRODUCT/DETAIL/MATCHES/ERROR",
  // events to ranking product
  EVENT_RANKING_SELECT_TOP: "/RANKING/SELECT/TOP",
  EVENT_RANKING_SELECT_BRAND: "/RANKING/SELECT/BRAND",
  EVENT_RANKING_SELECT_RETAILER: "/RANKING/SELECT/RETAILER",
  EVENT_RANKING_SHARE_CATEGORY: "/RANKING/SHARE_CATEGORY",
  EVENT_RANKING_SHARE_CATEGORY_BRAND: "/RANKING/SHARE_CATEGORY/BRAND",
  EVENT_RANKING_SHARE_CATEGORY_PRODUCTS: "/RANKING/SHARE_CATEGORY/PRODUCTS",
  // events to management summary product
  EVENT_MANAGEMENT_SUMMARY_EXPLORE_MIECOMMERCE: "/EXPLORE_MIECOMMERCE",
  EVENT_MANAGEMENT_SUMMARY_HISTORICAL_CANONICAL_CATEGORIES:
    "/REPORT/HISTORICAL_CANONICAL/CATEGORIES",
  EVENT_MANAGEMENT_SUMMARY_MATCHES_CATEGORIES: "/REPORT/MATCHES/CATEGORIES",
  EVENT_RANKING_PRODUCTS_DETAIL: "RANKING/PRODUCTS/DETAIL",
  EVENT_RANKING_DETAIL_CATEGORY: "RANKING/DETAIL/CATEGORY",
  EVENT_RANKING_DETAIL_DATES: "RANKING/DETAIL/DATES",
} as const

export const GOOGLE_TAG_CATEGORY = {
  CATEGORY_EVENT_LABEL_CREATION: "LABEL_CREATION",
  CATEGORY_EVENT_LABEL_EDITION: "LABEL_EDITION",
  CATEGORY_EVENT_DOWNLOAD: "DOWNLOAD",
  CATEGORY_EVENT_UNIFIED_MENU: "UNIFIED_MENU",
  MISSING_MATCH_REPORT: "MISSING_MATCH",
  WRONG_MATCH_REPORT: "WRONG_MATCH",
  WRONG_PRICE_REPORT: "WRONG_PRICE",
  // category to ranking product
  RANKING_MAIN_FILTER: "MAIN_FILTER",
  RANKING_REPORT_FILTER: "REPORT_FILTER",
  RANKING_DETAIL_FILTER: "DETAIL_FILTER",
} as const

export const GOOGLE_TAG_LABEL = {
  LABEL_EVENT_MENU_BAR_LEFT: "MENU_BAR_LEFT",
  LABEL_EVENT_DOWNLOAD: "TOP_BAR",
  LABEL_EVENT_MODAL: "MODAL",
  LABEL_EVENT_TOOLBAR: "TOOLBAR",
  LABEL_EVENT_MAIN_ACTION: "MAIN_ACTION",
  LABEL_EVENT_ALERTABLE: "ALERTABLE",
  LABEL_EVENT_NOT_ALERTABLE: "NOT_ALERTABLE",
} as const

export const GOOGLE_TAG_PAGE_VIEW = {
  VISTA_PRODUCT_LIST: "/PRODUCT/LIST",
  VISTA_REPORTE_CATEGORIA: "/PRODUCT/LIST/CATEGORY_REPORT",
  VISTA_REPORTE_MARCA: "/PRODUCT/LIST/BRAND_REPORT",
  VISTA_REPORTE_HISTORICO: "/PRODUCT/LIST/COMP_HISTORICAL_REPORT",
  VISTA_DETALLE_PRODUCTO: "/PRODUCT/DETAIL",
  VISTA_REPORTE_COMPETIDOR: "/PRODUCT/LIST/COMPETITOR_REPORT",
  PATH_SEARCH_FILTER: "/PRODUCT/LIST/FILTER/SEARCH",
  PATH_LABEL_FILTER: "/PRODUCT/LIST/FILTER/LABEL",
} as const

export const EVENT_MANAGEMENT_SUMMARY_DATE_RANGE: Record<string, string> = {
  HISTORICAL_CANONICAL: "/REPORT/HISTORICAL_CANONICAL/DATE_RANGE",
  ASSORTMENT_SIZE: "/REPORT/ASSORTMENT_SIZE/DATE_RANGE",
  ASSORTMENT_INDICATORS: "/REPORT/ASSORTMENT_INDICATORS/DATE_RANGE",
} as const

// Diccionario para rutas COMPETITORS_FILTER
export const EVENT_MANAGEMENT_SUMMARY_COMPETITORS_FILTER: Record<
  string,
  string
> = {
  HISTORICAL_CANONICAL: "/REPORT/HISTORICAL_CANONICAL/COMPETITORS_FILTER",
  ASSORTMENT_SIZE: "/REPORT/ASSORTMENT_SIZE/COMPETITORS_FILTER",
  ASSORTMENT_INDICATORS: "/REPORT/ASSORTMENT_INDICATORS/COMPETITORS_FILTER",
} as const

export const GOOGLE_TAG_TIME_CATEGORY: Record<string, string> = {
  6: "7_dias",
  7: "7_dias",
  29: "30_dias",
  30: "30_dias",
  89: "90_dias",
  90: "90_dias",
  OTHER: "calendario",
} as const
