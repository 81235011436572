/// <reference types="vite/client" />

const {
  DEV,
  PROD,
  MODE,
  VITEST,
  VITE_API_DOMAIN,
  VITE_API_KEY,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_GOOGLE_TAG_ID,
  VITE_INTERCOM_APP_ID,
  VITE_CHURNZERO_API,
  VITE_CHURNZERO_APP_KEY,
  VITE_HOTJAR_ID,
  VITE_HOTJAR_VERSION,
  VITE_PRODUCT_SEARCH,
  VITE_EXPORT_SERVICE,
} = import.meta.env

export const ENV = {
  /* c8 ignore next */
  DEV: DEV || MODE === "development",
  PROD: PROD && MODE === "production",
  VITEST: Boolean(VITEST),
  API_DOMAIN: String(VITE_API_DOMAIN),
  API_KEY: String(VITE_API_KEY),
  AUTH0_DOMAIN: String(VITE_AUTH0_DOMAIN),
  AUTH0_CLIENT_ID: String(VITE_AUTH0_CLIENT_ID),
  GOOGLE_TAG_ID: String(VITE_GOOGLE_TAG_ID),
  INTERCOM_APP_ID: String(VITE_INTERCOM_APP_ID),
  CHURNZERO_API: String(VITE_CHURNZERO_API),
  CHURNZERO_APP_KEY: String(VITE_CHURNZERO_APP_KEY),
  HOTJAR_ID: String(VITE_HOTJAR_ID),
  HOTJAR_VERSION: String(VITE_HOTJAR_VERSION),
  PRODUCT_SEARCH: String(VITE_PRODUCT_SEARCH),
  EXPORT_SERVICE: String(VITE_EXPORT_SERVICE),
} as const
