import { useAuth0 } from "@auth0/auth0-react"
import { ReactNode, useEffect } from "react"
import { useLogout } from "../hooks/use-logout"

export function AuthErrorHandler({
  children,
}: Readonly<{ children: ReactNode }>) {
  const { error } = useAuth0()
  const logout = useLogout()

  useEffect(() => {
    if (error) {
      console.error(error)
      logout()
    }
  }, [error, logout])

  return children
}
