import { ProductAccessOutput, StoreAccess } from "@rc/shared-types"
import axios, { GenericAbortSignal } from "axios"
import { join } from "lodash"
import { ENDPOINTS } from "../utils/endpoints"

export async function fetchProductAccess(storeIds: number[]) {
  const { data } = await axios.get<ProductAccessOutput[]>(
    ENDPOINTS.PRODUCT_ACCESS,
    {
      params: { storeIds: join(storeIds, ",") },
    }
  )
  return data
}

export async function fetchStoreAccess(
  storeId: number,
  signal?: GenericAbortSignal
) {
  return await axios.get<StoreAccess>(
    `${ENDPOINTS.STORES}/${storeId}/store_access`,
    { signal }
  )
}

export async function fetchModuleCompetitors(
  storeId: number,
  signal?: GenericAbortSignal
) {
  const { data } = await axios.get<ProductAccessOutput[]>(
    `${ENDPOINTS.PRODUCT_ACCESS}?storeId=${storeId}&fields=PRODUCT_ACCESS_ONLY_COMPETITORS`,
    { signal }
  )
  return data
}
