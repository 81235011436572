import { ChurnZero_, CommandParams } from "./types"

declare global {
  interface Window {
    ChurnZero: ChurnZero_
  }
}

// ChurnZero API: https://support.churnzero.com/hc/en-us/articles/360004683552-Integrate-ChurnZero-using-Javascript
class ChurnZero {
  hasLoaded = false

  initialize(apiUrl: string, appId: string) {
    if (this.hasLoaded) return

    const script = document.createElement("script")
    script.text = `
      var ChurnZero = ChurnZero || [];
      (function(a, b, c, d) {
        var cz = a.createElement(b);
        cz.type = c;
        cz.async = true;
        cz.src = d;
        var s = a.getElementsByTagName(b)[0];
        s.parentNode.insertBefore(cz, s);
      })(document, "script", 'text/javascript', '${apiUrl}/churnzero.js');
      
      ChurnZero.push(['setAppKey', '${appId}']);
    `
    document.body.appendChild(script)
    this.hasLoaded = true
  }

  setContact(...params: CommandParams["setContact"]) {
    if (!this.hasLoaded) return
    window.ChurnZero.push(["setContact", ...params])
  }

  trackEvent(...params: CommandParams["trackEvent"]) {
    if (!this.hasLoaded) return
    window.ChurnZero.push(["trackEvent", ...params])
  }

  setAuth(...params: CommandParams["setAuth"]) {
    if (!this.hasLoaded) return
    window.ChurnZero.push(["setAuth", ...params])
  }

  open() {
    if (!this.hasLoaded) return
    window.ChurnZero.push(["open"])
  }

  close() {
    if (!this.hasLoaded) return
    window.ChurnZero.push(["close"])
  }
}

export default new ChurnZero()
