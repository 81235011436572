import { cloneElement, useRef, useState } from "react"
import { Tooltip, placementTooltip } from "../tooltip/tooltip"

type Prop = {
  element: JSX.Element
  children: string
  placement?: placementTooltip
  classNameTooltip?: string
  showTooltip?: boolean
}

export const ElementWithTooltip = ({
  element,
  children,
  placement,
  classNameTooltip,
  showTooltip = true,
}: Prop) => {
  const ref = useRef(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const onMouseEnter = () => setOpenTooltip(true)
  const onMouseLeave = () => setOpenTooltip(false)

  const Component = cloneElement(element, {
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    ref: ref,
  })

  return (
    <>
      {Component}
      {showTooltip ? (
        <Tooltip
          target={ref}
          isOpen={openTooltip}
          placement={placement}
          customClassName={classNameTooltip}>
          {children}
        </Tooltip>
      ) : null}
    </>
  )
}
