import {
  ChurnZeroTokenParams,
  IntercomCreateTicketParams,
} from "@rc/shared-typescript-types"
import axios from "axios"
import { ENDPOINTS } from "../helper/endpoints"

export async function createIntercomTicket(params: IntercomCreateTicketParams) {
  const { data } = await axios.post(ENDPOINTS.INTERCOM.CREATE_TICKET, params)
  return data
}

export async function getIntercomToken(userId: string) {
  const { data } = await axios.get(ENDPOINTS.INTERCOM.TOKEN, {
    params: { userId },
  })
  return data
}

export async function getChurnZeroUserToken(params: ChurnZeroTokenParams) {
  const { data } = await axios.post<{ token: string }>(
    ENDPOINTS.CHURNZERO.TOKEN,
    params,
  )
  return data
}
