import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { OptionalKeys } from "@rc/shared-react-types"
import { merge } from "lodash"
import { ReactNode, useEffect, useMemo, useState } from "react"
import SelectReact, {
  components,
  DropdownIndicatorProps,
  MenuPosition,
  MultiValue,
  PropsValue,
  SingleValueProps,
  StylesConfig,
} from "react-select"
import { SelectComponentsGeneric } from "react-select/dist/declarations/src/components"
import { SelectOption } from "./select-option"
import "./select.scss"
import { stylesMultiSelect } from "./styles-multi-select"
import { stylesSelect } from "./styles-select"

interface SelectProps {
  options: Array<SelectOption>
  defaultSelect?: PropsValue<SelectOption>
  isMulti?: boolean
  prefix?: string
  className?: string
  menuPosition?: MenuPosition
  defaultMenuIsOpen?: boolean
  placeholder?: ReactNode
  isSearchable?: boolean
  isLoading?: boolean
  classNamePrefix?: string
  dispatchEvent(response: PropsValue<SelectOption>): void
  onInputChange?(response: string): void
  onMenuClose?(): void
  styles?: StylesConfig<SelectOption>
  CustomComponents?: OptionalKeys<SelectComponentsGeneric>
}

function Select({
  isMulti = false,
  options,
  defaultSelect,
  dispatchEvent,
  prefix,
  className = "",
  menuPosition = "fixed",
  defaultMenuIsOpen = false,
  placeholder,
  isSearchable,
  classNamePrefix = "",
  isLoading = false,
  styles = {},
  onInputChange,
  onMenuClose,
  CustomComponents = {},
}: Readonly<SelectProps>) {
  const [option, setOption] = useState<PropsValue<SelectOption> | undefined>(
    defaultSelect,
  )

  useEffect(() => {
    if (defaultSelect) setOption(defaultSelect)
  }, [defaultSelect])

  const handleChange = (option: PropsValue<SelectOption>) => {
    if (!option) return
    setOption(option)
    if (isMulti) {
      const newOptions = option as MultiValue<SelectOption>
      dispatchEvent(Array.from(newOptions.values()))
    } else {
      dispatchEvent(option)
    }
  }

  const SingleComponents = {
    SingleValue: (props: SingleValueProps<SelectOption>) => (
      <components.SingleValue {...props}>
        {prefix && <span className="select__prefix">{`${prefix} `}</span>}
        {props.children}
      </components.SingleValue>
    ),
    ...CustomComponents,
  }

  const MultiComponents = {
    DropdownIndicator: ({
      selectProps,
    }: DropdownIndicatorProps<SelectOption>) =>
      selectProps.menuIsOpen ? <ArrowDropUp /> : <ArrowDropDown />,
  }

  const _styles = useMemo(() => {
    return merge(isMulti ? stylesMultiSelect : stylesSelect, styles)
  }, [isMulti, styles])

  return (
    <SelectReact
      isMulti={isMulti}
      className={`select ${className}`}
      options={options}
      value={option}
      closeMenuOnScroll={true}
      onChange={handleChange}
      menuPosition={menuPosition}
      onMenuClose={onMenuClose}
      defaultMenuIsOpen={defaultMenuIsOpen}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isLoading={isLoading}
      styles={_styles}
      classNamePrefix={classNamePrefix}
      components={
        (isMulti
          ? MultiComponents
          : SingleComponents) as SelectComponentsGeneric
      }
      onInputChange={onInputChange}
    />
  )
}

export { Select }
