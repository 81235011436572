import { default as rcIcon } from "../../images/rc-icon.png"
import "./spinner.scss"

function Spinner() {
  return (
    <div className="spinner" data-testid="spinner">
      <img src={rcIcon} alt="retailcompass-icon" className="spinner-logo" />
    </div>
  )
}

export { Spinner }
