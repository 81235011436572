/// <reference types="gtag.js" />
// Google Tag API: https://developers.google.com/tag-platform/gtagjs/reference
// Google Analytics API: https://developers.google.com/analytics/devguides/collection/gtagjs/events

declare global {
  interface Window {
    dataLayer: Array<unknown>
  }
}

class GoogleTag {
  hasLoaded = false

  initialize(...args: Gtag.GtagCommands["config"]) {
    if (this.hasLoaded) return
    const [tagId] = args

    const script = document.createElement("script")
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`
    document.body.appendChild(script)

    window.dataLayer = window.dataLayer || []
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }

    window.gtag("js", new Date())
    window.gtag("config", ...args)
    this.hasLoaded = true
  }

  set(...args: Gtag.GtagCommands["set"]) {
    if (!this.hasLoaded) return
    window.gtag("set", ...args)
  }

  get(...args: Gtag.GtagCommands["get"]) {
    if (!this.hasLoaded) return
    window.gtag("get", ...args)
  }

  event(...args: Gtag.GtagCommands["event"]) {
    if (!this.hasLoaded) return
    window.gtag("event", ...args)
  }

  config(...args: Gtag.GtagCommands["config"]) {
    if (!this.hasLoaded) return
    window.gtag("config", ...args)
  }

  consent(...args: Gtag.GtagCommands["consent"]) {
    if (!this.hasLoaded) return
    window.gtag("consent", ...args)
  }
}

export default new GoogleTag()
