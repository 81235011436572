import { queryClient } from "@rc/shared-query-provider"
import { createSelectors } from "@rc/shared-state-util"
import { getModuleCompetitors } from "@rc/shared-store-catalog"
import { SessionInformation, Store, User } from "@rc/shared-types"
import { getDefaultStore } from "@rc/shared-user-catalog"
import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"
import { AuthState, AuthStore } from "./auth-store"

const initialState: AuthState = {
  user: {} as User,
  store: {} as Store,
  stores: [],
  productAccesses: [],
  moduleCompetitors: [],
  sessionInformation: {} as SessionInformation,
}

export const authStore = create<AuthStore>()(
  subscribeWithSelector((set, get) => ({
    ...initialState,

    actions: {
      setUserData: (user, sessionInformation, productAccesses) => {
        const { stores } = sessionInformation
        set({ user, sessionInformation, productAccesses })
        get().actions.setStores(stores)
        get().actions.setStore(getDefaultStore(stores))
      },

      setStores: (stores) => set({ stores }),

      setStore: (store) => set({ store }),

      setModuleCompetitors: async (storeId) => {
        const moduleCompetitors = await queryClient.fetchQuery(
          getModuleCompetitors(storeId)
        )
        set({ moduleCompetitors })
      },
    },
  }))
)

export const useAuthStore = createSelectors(authStore)
