export class SelectOption {
  value: string
  label: string

  constructor(value: string, label: string) {
    this.value = value
    this.label = label
  }

  static buildSelectOption(value: string, label: string): SelectOption {
    return new SelectOption(value, label)
  }
}
