/// <reference types="intercom-web" />
// Intercom API: https://developers.intercom.com/installing-intercom/web/methods/

class Intercom {
  hasLoaded = false

  initialize(appId: string) {
    if (this.hasLoaded) return

    const script = document.createElement("script")
    script.text = `
      (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/${appId}';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();    
    `
    document.body.appendChild(script)
    this.hasLoaded = true
  }

  boot(settings: Intercom_.IntercomSettings) {
    if (!this.hasLoaded) return
    window.Intercom("boot", settings)
  }

  update(settings?: Intercom_.IntercomSettings) {
    if (!this.hasLoaded) return
    window.Intercom("update", settings)
  }

  trackEvent(tag: string, metadata?: unknown) {
    if (!this.hasLoaded) return
    window.Intercom("trackEvent", tag, metadata)
  }
}

export default new Intercom()
