export enum CHURN_ZERO_PHOENIX {
  LOGIN = "phoenix_login",
  VISTA_PRODUCT_LIST = "phoenix_vista_sku",
  VISTA_REPORTE_CATEGORIA = "phoenix_vista_reporte_categoria",
  VISTA_REPORTE_MARCA = "phoenix_vista_reporte_marca",
  VISTA_REPORTE_HISTORICO = "phoenix_vista_reporte_competitividad_historica",
  VISTA_REPORTE_COMPETIDOR = "phoenix_vista_reporte_competidor",
  VISTA_DETALLE_PRODUCTO = "phoenix_detalle_producto",
  EVENT_DESCARGAR_EXCEL = "phoenix_descarga_excel",
  EVENT_FILTRO_MARCA = "phoenix_filtro_marca",
  EVENT_FILTRO_RETAIL = "phoenix_filtro_retailer",
  EVENT_FILTRO_MATCHES = "phoenix_filtro_matches",
  EVENT_FILTRO_BUSCADOR = "phoenix_buscador",
  EVENT_FILTRO_LABEL = "phoenix_filtro_label",
  EVENT_LABEL_CREADO = "phoenix_label_creado",
}

export enum CHURN_ZERO_RANKING {
  EVENT_FILTRO_RETAILER = "ranking_filtro_retailer",
  EVENT_FILTRO_TOP = "ranking_filtro_top",
  EVENT_FILTRO_BRAND = "ranking_filtro_brand",
  EVENT_SHARE_CATEGORY = "ranking_share_category",
  EVENT_PRODUCT_LIST = "ranking_listado_productos",
  EVENT_DETAIL_OPEN = "ranking_producto_detalle",
  EVENT_DETAIL_CATEGORY = "ranking_producto_detalle_categoria",
  EVENT_DETAIL_DATES = "ranking_producto_detalle_fechas",
}
