import { Auth0User, User } from "@rc/shared-types"

export function parseAuth0User(auth0User: Auth0User): User {
  const {
    id,
    name,
    email,
    userType,
    isRealUser,
    jobTitle,
    role,
    strategic,
    created,
    username,
  } = auth0User["http://api.retailcompass.com/user-catalog"]

  return {
    id,
    name,
    username,
    email,
    role,
    jobTitle,
    userType,
    isRealUser,
    strategic,
    created,
  }
}
