import { useAuthStore } from "@rc/shared-auth-provider"
import { db } from "@rc/shared-local-storage"
import { Store } from "@rc/shared-types"
import { Select, SelectOption } from "@rc/shared-ui"
import { find } from "lodash"
import "./store-selector.scss"

interface StoreSelectorProps {
  stores: Store[]
}

function StoreSelector({ stores }: Readonly<StoreSelectorProps>) {
  const { setStore } = useAuthStore.use.actions()
  const store = useAuthStore.use.store()

  const defaultOption = SelectOption.buildSelectOption(
    `${store.id}`,
    `${store.internalName}`
  )

  const options = stores.map((store) =>
    SelectOption.buildSelectOption(`${store.id}`, `${store.internalName}`)
  )

  const callDispatch = async (option: SelectOption) => {
    const selectedStore = find(stores, ["id", parseInt(`${option.value}`)])
    if (selectedStore) {
      setStore(selectedStore)
      db.selectedStore.put({
        id: "last-selected",
        storeId: selectedStore.id,
        countryCode: selectedStore.country,
      })
    }
  }

  return (
    <div data-testid="store-selector" className="header-store-selector">
      <Select
        options={options}
        defaultSelect={defaultOption}
        dispatchEvent={callDispatch}
      />
    </div>
  )
}

export { StoreSelector }
