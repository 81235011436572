import { ProductAccess, ProductAccessOutput, Store } from "@rc/shared-types"
import { camelCase, flatten, sortBy, uniq } from "lodash"

export function parseProductAccess(
  productAccessOutput: ProductAccessOutput[],
  stores: Store[]
): ProductAccess[] {
  const allowedApps = uniq(flatten(stores.map((store) => store.allowedApps)))
  const productAccess = productAccessOutput.map((product) => ({
    ...product,
    enabled: allowedApps.includes(product.internalName),
    pathname: camelCase(product.name).toLowerCase(),
  }))
  return sortBy(productAccess, ["order"])
}
