import { es } from "date-fns/locale"
import moment, { Moment } from "moment"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./datepicker.scss"
import { InputDatePicker } from "./input-datepicker"

registerLocale("es", es)

interface Props {
  selected: Moment
  onChange: (date: Moment) => void
  minDate?: Moment
  maxDate?: Moment
  startDate?: Moment
  endDate?: Moment
  selectsStart?: boolean
  selectsEnd?: boolean
}
export function DatePicker({
  selected,
  maxDate,
  minDate,
  endDate,
  selectsEnd,
  selectsStart,
  startDate,
  onChange,
}: Readonly<Props>) {
  const handleChange = (date: Date) => {
    onChange(moment(date))
  }

  return (
    <ReactDatePicker
      selected={selected.toDate()}
      onChange={handleChange}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate?.toDate()}
      endDate={endDate?.toDate()}
      minDate={minDate?.toDate()}
      maxDate={maxDate?.toDate()}
      locale="es"
      dateFormat={"dd/MM/yyyy"}
      customInput={<InputDatePicker />}
    />
  )
}
