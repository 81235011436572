import { rcLogo } from "@rc/shared-ui"
import { UserSection } from "../user-section/user-section"
import "./header.scss"

interface HeaderProps {
  children?: React.ReactNode
}

export function Header({ children }: Readonly<HeaderProps>) {
  return (
    <header className="header">
      <img src={rcLogo} alt="retailcompass-logo" />
      {children}
      <UserSection />
    </header>
  )
}
