/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import { StoreApi, useStore } from "zustand"

type WithSelectors<TStore> = TStore extends { getState: () => infer T }
  ? TStore & { use: { [K in keyof T]: () => T[K] } }
  : never

export const createSelectors = <TStore extends StoreApi<object>>(
  _store: TStore
) => {
  const store = _store as WithSelectors<typeof _store>
  store.use = {}
  for (const k of Object.keys(store.getState())) {
    // @ts-ignore: `use` es implícitamente any para crear los selectores de forma dinámica.
    store.use[k] = () => useStore(_store, (s) => s[k as keyof typeof s])
  }

  return store
}
