import { SessionInformation } from "@rc/shared-types"
import axios from "axios"
import { ENDPOINTS } from "../utils/endpoints"

export async function fetchSessionInformation(userId: number) {
  const { data } = await axios.post<SessionInformation>(
    ENDPOINTS.SESSION_INFORMATION,
    {
      id: userId,
      userFields: "ONLY_STORES",
    }
  )
  return data
}
