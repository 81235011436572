import { useAuthStore } from "@rc/shared-auth-provider"
import { isEmpty } from "lodash"
import { useCallback } from "react"
import { useNavigate } from "react-router"
import { LOCAL_MODULES } from "../../constants/constants"

export function useNavigateDefaultModule() {
  const productAccesses = useAuthStore.use.productAccesses()
  const navigate = useNavigate()

  const navigateDefaultModule = useCallback(() => {
    const availableModules = productAccesses.filter((p) => {
      const isLocalModule = LOCAL_MODULES.includes(p.internalName)
      return p.enabled && isLocalModule
    })

    if (isEmpty(availableModules)) {
      const urls = productAccesses.flatMap((p) => (p.enabled ? p.url : []))
      console.error(`No available modules, redirecting to ${urls[0]}`)
      window.open(urls[0], "_self")
    } else {
      navigate(`/${availableModules[0].pathname}`)
    }
  }, [navigate, productAccesses])

  return navigateDefaultModule
}
