import { Person } from "@mui/icons-material"
import { useAuthStore } from "@rc/shared-auth-provider"
import "./user-name.scss"

function UserName() {
  const user = useAuthStore.use.user()

  return (
    <div data-testid="username" className="username">
      <Person />
      {user.name}
    </div>
  )
}

export { UserName }
