import { Logout } from "../logout/logout"
import { UserName } from "../user-name/user-name"
import "./profile.scss"

function Profile() {
  return (
    <div data-testid="profile" className="profile">
      <UserName />
      <Logout />
    </div>
  )
}

export { Profile }
