import { useAuthStore } from "@rc/shared-auth-provider"
import { useMemo } from "react"
import { Profile } from "../profile/profile"
import { StoreSelector } from "../store-selector/store-selector"
import "./user-section.scss"

function UserSection() {
  const stores = useAuthStore.use.stores()
  const hasMultipleStores = useMemo(() => stores.length > 1, [stores])

  return (
    <div data-testid="user-section" className="user-section">
      <Profile />
      {hasMultipleStores && <StoreSelector stores={stores} />}
    </div>
  )
}

export { UserSection }
