import { ArrowBackIos, Info } from "@mui/icons-material"
import { churnZero } from "@rc/shared-third-party-tools"
import { useState } from "react"
import "./churn-zero-button.scss"

export function ChurnZeroButton() {
  const [isOpened, setIsOpened] = useState(false)

  const toggleSuccessCenter = () => {
    const element = document.querySelector(".cz-sc-open")

    if (element) {
      churnZero.close()
      setIsOpened(false)
    } else {
      churnZero.open()
      setIsOpened(true)
    }
  }

  return (
    <button
      data-testid="churn-zero-button"
      className="churn-zero-button"
      onClick={toggleSuccessCenter}>
      {isOpened ? <ArrowBackIos className="close-icon" /> : <Info />}
    </button>
  )
}
