import { useAuth0 } from "@auth0/auth0-react"
import { useCallback } from "react"

export function useLogout() {
  const { logout } = useAuth0()

  return useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }, [logout])
}
