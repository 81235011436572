import { Auth0Provider } from "@auth0/auth0-react"
import { ENV } from "@rc/shared-react-common"
import { UserAuthentication } from "../authentication/user-authentication"
import { AuthErrorHandler } from "../helper/auth-error-handler"

interface AuthProviderProps {
  children: React.ReactNode
}

export function AuthProvider({ children }: Readonly<AuthProviderProps>) {
  return (
    <Auth0Provider
      domain={ENV.AUTH0_DOMAIN}
      clientId={ENV.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}>
      <AuthErrorHandler>
        <UserAuthentication>{children}</UserAuthentication>
      </AuthErrorHandler>
    </Auth0Provider>
  )
}
