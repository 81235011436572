import Dexie, { Table } from "dexie"
import { FormatExcel } from "../types/format-excel"
import { SelectedStore } from "../types/selected-store"

class DexieWrapped extends Dexie {
  selectedStore!: Table<SelectedStore>
  formatExcel!: Table<FormatExcel>

  constructor() {
    super("retailcompass")
    this.version(2).stores({
      selectedStore: "&id, storeId, countryCode",
      formatExcel: "&storeId, format",
    })
  }
}

export const db = new DexieWrapped()
