import { PopperPlacementType } from "@mui/material"
import { Tooltip as ReactTooltip } from "reactstrap"
import "./tooltip.scss"

export type placementTooltip = PopperPlacementType

export interface TooltipProps {
  children: React.ReactNode
  target: React.RefObject<HTMLElement>
  isOpen: boolean
  placement?: placementTooltip
  customClassName?: string
}

function Tooltip({
  children,
  target,
  isOpen,
  placement = "top",
  customClassName = "",
}: Readonly<TooltipProps>) {
  return (
    <ReactTooltip
      data-testid="rc-tooltip"
      className={`rc-tooltip ${customClassName}`}
      placement={placement}
      target={target}
      isOpen={isOpen}>
      {children}
    </ReactTooltip>
  )
}
export { Tooltip }
