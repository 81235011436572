import { ChurnZeroTokenParams } from "@rc/shared-typescript-types"
import { queryOptions } from "@tanstack/react-query"
import { getChurnZeroUserToken } from "../api/third-party-api"

export function getChurnZeroUserTokenQuery(params: ChurnZeroTokenParams) {
  return queryOptions({
    queryKey: ["third-party-api", "churnzero", params],
    queryFn: async () => {
      const { token } = await getChurnZeroUserToken(params)
      return token
    },
  })
}
