import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { queryClient } from "@rc/shared-query-provider"
import { Auth0User } from "@rc/shared-types"
import { Spinner } from "@rc/shared-ui"
import { useCallback, useEffect, useState } from "react"
import { parseAuth0User } from "../helper/authentication-helper"
import { userDataQuery } from "../helper/user-query-helper"
import { useLogout } from "../hooks/use-logout"
import { useAuthStore } from "../state/use-auth-store"

const Component = ({ children }: Readonly<{ children: React.ReactNode }>) => {
  const [shouldRender, setShouldRender] = useState(false)
  const { setUserData } = useAuthStore.use.actions()
  const { user: auth0User } = useAuth0<Auth0User>()
  const logout = useLogout()

  const fetchUserData = useCallback(async () => {
    try {
      if (!auth0User) return
      const user = parseAuth0User(auth0User)
      const { sessionInformation, productAccesses } =
        await queryClient.fetchQuery(userDataQuery(user.id))
      setUserData(user, sessionInformation, productAccesses)
      setShouldRender(true)
    } catch (error) {
      console.error(error)
      logout()
    }
  }, [auth0User, logout, setUserData])

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  return shouldRender ? children : <Spinner />
}

export const UserAuthentication = withAuthenticationRequired(Component, {
  onRedirecting: () => <Spinner />,
})
