import { MULTI_PRODUCT_ROUTES } from "@rc/multi-product-util"
import { lazy } from "react"
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import App from "../app/app"

const EcommerceModule = lazy(() => import("@rc/ecommerce-module"))
const ManagementSummaryModule = lazy(
  () => import("@rc/management-summary-module"),
)
const RankingModule = lazy(() => import("@rc/ranking-module"))

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route
        path={MULTI_PRODUCT_ROUTES.ECOMMERCE + "/*"}
        element={<EcommerceModule />}
      />
      <Route
        path={MULTI_PRODUCT_ROUTES.MANAGEMENT_SUMMARY + "/*"}
        element={<ManagementSummaryModule />}
      />
      <Route
        path={MULTI_PRODUCT_ROUTES.RANKING + "/*"}
        element={<RankingModule />}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>,
  ),
)
