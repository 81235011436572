import { createSelectors } from "@rc/shared-state-util"
import { ProductAccess } from "@rc/shared-types"
import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"

interface MultiProductStore {
  module: ProductAccess
  actions: {
    setModule: (module: ProductAccess) => void
  }
}

export const multiProductStore = create<MultiProductStore>()(
  subscribeWithSelector((set) => ({
    module: {} as ProductAccess,
    actions: {
      setModule: (module: ProductAccess) => set({ module }),
    },
  }))
)

export const useMultiProductStore = createSelectors(multiProductStore)
