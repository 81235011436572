import { AuthProvider } from "@rc/shared-auth-provider"
import { QueryProvider } from "@rc/shared-query-provider"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../global.scss"
import { Header } from "../header/header"
import "./layout.scss"

interface LayoutProps {
  headerContent?: React.ReactNode
  children: React.ReactNode
}

export function Layout({ headerContent, children }: Readonly<LayoutProps>) {
  return (
    <QueryProvider>
      <AuthProvider>
        <section className="rc-layout" data-testid="rc-layout">
          <Header>{headerContent}</Header>
          <main>{children}</main>
        </section>
      </AuthProvider>
    </QueryProvider>
  )
}
