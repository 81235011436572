export enum Application {
  WEBAPP = "WEBAPP",
  SCALIBUR = "SCALIBUR",
  PHOENIX = "PHOENIX",
  MARKETPLACE = "MARKETPLACE",
  ASSORTMENT = "ASSORTMENT",
  MANAGEMENT_SUMMARY = "MANAGEMENT_SUMMARY",
  RANKING = "RANKING",
  VALIDATION = "VALIDATION",
}
