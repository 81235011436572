import { ProductAccessOutput, StoreAccess } from "@rc/shared-types"
import { UseQueryOptions } from "@tanstack/react-query"
import {
  fetchModuleCompetitors,
  fetchStoreAccess,
} from "../api/store-catalog-api"

export const GET_MODULE_COMPETITORS = "get_module_competitors"
export const GET_STORE_ACCESS = "get_store_access"

export function getStoreAccessOption(
  storeId: number
): UseQueryOptions<StoreAccess> {
  return {
    queryKey: [GET_STORE_ACCESS, storeId],
    queryFn: async ({ signal }): Promise<StoreAccess> => {
      const { data } = await fetchStoreAccess(storeId, signal)
      return data
    },
  }
}

export function getModuleCompetitors(
  storeId: number
): UseQueryOptions<ProductAccessOutput[]> {
  return {
    queryKey: [GET_MODULE_COMPETITORS, storeId],
    queryFn: async ({ signal }): Promise<ProductAccessOutput[]> => {
      return await fetchModuleCompetitors(storeId, signal)
    },
  }
}
