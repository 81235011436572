import { useAuthStore } from "@rc/shared-auth-provider"
import { MultiProductNavbarItem } from "../navbar-item/multi-product-navbar-item"
import "./multi-product-navbar.scss"

export function MultiProductNavbar() {
  const productAccesses = useAuthStore.use.productAccesses()

  return (
    <nav className="mp-navbar">
      {productAccesses.map((item) => (
        <MultiProductNavbarItem key={item.id} item={item} />
      ))}
    </nav>
  )
}
