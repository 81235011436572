import { MultiProductInitializer } from "@rc/multi-product-initializer"
import { MultiProductNavbar } from "@rc/multi-product-navbar"
import { Layout } from "@rc/shared-layout"
import { ENV } from "@rc/shared-react-common"
import { Spinner } from "@rc/shared-ui"
import axios from "axios"
import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import "./app.scss"

axios.interceptors.request.use((config) => {
  config.baseURL = config.baseURL ?? ENV.API_DOMAIN
  config.params = {
    ...config.params,
    apikey: config.params?.apikey ?? ENV.API_KEY,
  }
  return config
})

function App() {
  return (
    <Layout>
      <MultiProductInitializer>
        <div data-testid="app" className="app">
          <MultiProductNavbar />
          <section>
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </section>
        </div>
      </MultiProductInitializer>
    </Layout>
  )
}

export default App
