import {
  ChurnZeroButton,
  useNavigateDefaultModule,
} from "@rc/multi-product-util"
import { useAuthStore } from "@rc/shared-auth-provider"
import { ENV } from "@rc/shared-react-common"
import { ThirdPartyAppsInitializer } from "@rc/shared-third-party-tools"
import { isCustomerUser } from "@rc/shared-user-catalog"
import { ReactNode, useEffect } from "react"

export function MultiProductInitializer({ children }: { children: ReactNode }) {
  const user = useAuthStore.use.user()
  const defaultModule = useNavigateDefaultModule()
  const pathname = window.location.pathname
  const showChurnZero = isCustomerUser(user.role) || ENV.DEV

  useEffect(
    function defaultModuleNavigation() {
      if (pathname === "/") defaultModule()
    },
    [defaultModule, pathname],
  )

  return (
    <ThirdPartyAppsInitializer initialize={"ALL"} user={user}>
      <>
        {children}
        {showChurnZero && <ChurnZeroButton />}
      </>
    </ThirdPartyAppsInitializer>
  )
}
